.SumoSelect {
	p {
	  margin: 0;
	}
  
	width: 200px;
  }
  
  .SelectBox {
	padding: 5px 8px;
  }
  
  .sumoStopScroll {
	overflow: hidden;
  }
  
  /* Filtering style */
  
  .SumoSelect {
	.hidden {
	  display: none;
	}
  
	.search-txt {
	  display: none;
	  outline: none;
	}
  
	.no-match {
	  display: none;
	  padding: 6px;
	}
  
	&.open {
	  .search-txt {
		display: inline-block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		margin: 0;
		padding: 5px 15px;
		border: none;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		border-radius: 5px;
	  }
  
	  > .search > {
		span, label {
		  visibility: hidden;
		}
	  }
	}
  }
  
  /*this is applied on that hidden select. DO NOT USE display:none; or visiblity:hidden; and Do not override any of these properties. */
  
  .SelectClass, .SumoUnder {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
	border: none;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity = 0);
	-moz-opacity: 0;
	-khtml-opacity: 0;
	opacity: 0;
  }
  
  .SelectClass {
	z-index: 1;
  }
  
  .SumoSelect {
	> {
	  .optWrapper > .options li.opt label, .CaptionCont {
		user-select: none;
		-o-user-select: none;
		-moz-user-select: none;
		-khtml-user-select: none;
		-webkit-user-select: none;
	  }
	}
  
	.select-all > label {
	  user-select: none;
	  -o-user-select: none;
	  -moz-user-select: none;
	  -khtml-user-select: none;
	  -webkit-user-select: none;
	}
  
	display: inline-block;
	position: relative;
	outline: none;
	width: 100%;
  
	> {
	  .CaptionCont {
		position: relative;
		border: 1px solid #e9ebfa;
		color: #424e79;
		min-height: 14px;
		background-color: #fff;
		border-radius: .25rem;
		margin: 0;
		width: 100%;
		height: 40px;
		line-height: 30px;
		padding: 6px 17px;
  
		> {
		  span {
			display: block;
			padding-right: 30px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			cursor: default;
  
			&.placeholder {
			  color: #9999b5;
			  font-style: italic;
			}
		  }
  
		  label {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: 30px;
  
			> i:before {
			  display: inline-block;
			  width: 0;
			  height: 0;
			  margin-left: .255em;
			  vertical-align: .255em;
			  content: "";
			  border-top: .3em solid;
			  border-right: .3em solid transparent;
			  border-bottom: 0;
			  border-left: .3em solid transparent;
			  margin-top: 18px;
			  color: #bcccda;
			}
		  }
		}
	  }
  
	  .optWrapper {
		display: none;
		z-index: 1;
		top: 30px;
		width: 100%;
		position: absolute;
		left: 0;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		background: #fff;
		border: 1px solid #eaeaec;
		border-radius: 3px;
		overflow: hidden;
	  }
	}
  
	&.open > .optWrapper {
	  top: 35px;
	  display: block;
	  box-shadow: 0px 16px 18px rgba(6, 10, 48, 0.1);
	  background: #fff;
	  border-radius: 0 0 5px 5px;
  
	  &.up {
		top: auto;
		bottom: 100%;
		margin-bottom: 5px;
	  }
	}
  
	> .optWrapper {
	  ul {
		list-style: none;
		display: block;
		padding: 0;
		margin: 0;
		overflow: auto;
	  }
  
	  > .options {
		border-radius: 2px;
		position: relative;
  
		/*Set the height of pop up here (only for desktop mode)*/
		max-height: 250px;
  
		/*height*/
	  }
  
	  &.okCancelInMulti > .options {
		border-radius: 2px 2px 0 0;
	  }
  
	  &.selall {
		> .options {
		  border-radius: 0 0 2px 2px;
		}
  
		&.okCancelInMulti > .options {
		  border-radius: 0;
		}
	  }
  
	  > .options li {
		&.group.disabled > label {
		  opacity: 0.5;
		}
  
		ul li.opt {
		  padding-left: 22px;
		}
	  }
  
	  &.multiple > .options li ul li.opt {
		padding-left: 50px;
	  }
  
	  &.isFloating > .options {
		max-height: 100%;
	  }
  
	  > .options {
		li.opt {
		  padding: 0px 16px;
		  position: relative;
		  border-bottom: 1px solid #eff0f6;
		  height: 40px;
		  line-height: 40px;
		}
  
		> li.opt:first-child {
		  border-radius: 2px 2px 0 0;
		}
	  }
  
	  &.selall > .options > li.opt:first-child {
		border-radius: 0;
	  }
  
	  > .options > li.opt:last-child {
		border-radius: 0 0 2px 2px;
		border-bottom: none;
	  }
  
	  &.okCancelInMulti > .options > li.opt:last-child {
		border-radius: 0;
	  }
  
	  > .options li.opt {
		&:hover {
		  background-color: #f4f5fb;
		}
  
		&.sel {
		  background-color: #a1c0e4;
		}
	  }
	}
  
	.select-all {
	  &.sel {
		background-color: #a1c0e4;
	  }
  
	  > span {
		position: absolute;
		display: block;
		width: 30px;
		top: 0;
		bottom: 0;
		margin-left: -35px;
	  }
  
	  > span i {
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: 14px;
		height: 14px;
		border: 1px solid #eff0f6;
		border-radius: 2px;
		background-color: #fff;
	  }
  
	  &.selected > span i, &.partial > span i {
		background-color: #6259ca;
		box-shadow: none;
		border-color: transparent;
		background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAGCAYAAAD+Bd/7AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNXG14zYAAABMSURBVAiZfc0xDkAAFIPhd2Kr1WRjcAExuIgzGUTIZ/AkImjSofnbNBAfHvzAHjOKNzhiQ42IDFXCDivaaxAJd0xYshT3QqBxqnxeHvhunpu23xnmAAAAAElFTkSuQmCC');
		background-repeat: no-repeat;
		background-position: center center;
	  }
	}
  
	> .optWrapper {
	  > .options li {
		label {
		  text-overflow: ellipsis;
		  white-space: nowrap;
		  overflow: hidden;
		  display: block;
		  cursor: pointer;
		  margin-bottom: 0 !important;
		}
  
		span {
		  display: none;
		}
  
		&.group > label {
		  cursor: default;
		  padding: 8px 6px;
		  font-weight: bold;
		}
	  }
  
	  &.isFloating {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		width: 90%;
		bottom: 0;
		margin: auto;
		max-height: 90%;
	  }
  
	  > .options li.opt.disabled {
		background-color: inherit;
		pointer-events: none;
  
		* {
		  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  
		  /* IE 5-7 */
		  filter: alpha(opacity = 50);
  
		  /* Netscape */
		  -moz-opacity: 0.5;
  
		  /* Safari 1.x */
		  -khtml-opacity: 0.5;
  
		  /* Good browsers */
		  opacity: 0.5;
		}
	  }
  
	  &.multiple > .options li.opt {
		padding-left: 35px;
		cursor: pointer;
  
		span {
		  position: absolute;
		  display: block;
		  width: 30px;
		  top: 0;
		  bottom: 0;
		  margin-left: -35px;
		}
	  }
	}
  
	> .optWrapper.multiple > .options li.opt span i {
	  position: absolute;
	  margin: auto;
	  left: 0;
	  right: 0;
	  top: 0;
	  bottom: 0;
	  width: 14px;
	  height: 14px;
	  border: 1px solid #eff0f6;
	  border-radius: 2px;
	  background-color: #fff;
	}
  
	> .optWrapper {
	  > .MultiControls {
		display: none;
		border-top: 1px solid #eaeaec;
		background-color: #fff;
		border-radius: 0 0 3px 3px;
	  }
  
	  &.multiple {
		&.isFloating > .MultiControls {
		  display: block;
		  margin-top: 5px;
		  position: absolute;
		  bottom: 0;
		  width: 100%;
		}
  
		&.okCancelInMulti > .MultiControls {
		  display: block;
  
		  > p {
			padding: 6px;
  
			&:focus {
			  border-color: #6259ca;
			  outline: none;
			  background-color: #6259ca;
			}
		  }
		}
  
		> .MultiControls > p {
		  display: inline-block;
		  cursor: pointer;
		  padding: 12px;
		  width: 50%;
		  box-sizing: border-box;
		  text-align: center;
  
		  &:hover {
			background-color: #f4f5fb;
		  }
  
		  &.btnOk {
			border-right: 1px solid #DBDBDB;
			border-radius: 0 0 0 3px;
		  }
  
		  &.btnCancel {
			border-radius: 0 0 3px 0;
		  }
		}
	  }
  
	  &.isFloating > .options li.opt {
		padding: 12px 6px;
	  }
  
	  &.multiple {
		&.isFloating {
		  > .options li.opt {
			padding-left: 35px;
		  }
  
		  padding-bottom: 43px;
		}
  
		> .options li.opt.selected span i {
		  background-color: #6259ca;
		  box-shadow: none;
		  border-color: transparent;
		  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAGCAYAAAD+Bd/7AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNXG14zYAAABMSURBVAiZfc0xDkAAFIPhd2Kr1WRjcAExuIgzGUTIZ/AkImjSofnbNBAfHvzAHjOKNzhiQ42IDFXCDivaaxAJd0xYshT3QqBxqnxeHvhunpu23xnmAAAAAElFTkSuQmCC');
		  background-repeat: no-repeat;
		  background-position: center center;
		}
	  }
	}
  
	&.disabled {
	  opacity: 0.7;
	  cursor: not-allowed;
  
	  > .CaptionCont {
		border-color: #d3dfea;
		box-shadow: none;
		background: #f4f5f9;
	  }
	}
  
	.select-all {
	  border-radius: 3px 3px 0 0;
	  position: relative;
	  border-bottom: 1px solid #eaeaec;
	  background-color: #fff;
	  padding: 8px 0 3px 35px;
	  height: 36px;
	  cursor: pointer;
  
	  > {
		label, span i {
		  cursor: pointer;
		}
	  }
  
	  &.partial > span i {
		background-color: #eaeaec;
	  }
	}
  
	> .optWrapper > .options li.optGroup {
	  padding-left: 5px;
	  text-decoration: underline;
	}
  }
  
  /*placeholder style*/
  
  /*Floating styles*/
  
  /*disabled state*/
  
  /*styling for multiple select*/
  
  /*styling for select on popup mode*/
  
  /*styling for only multiple select on popup mode*/
  
  /*disabled state*/
  
  /**Select all button**/
  
  /*styling for optgroups*/